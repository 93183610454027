<template>
    <v-container grid-list-xs>
        <v-row>
            <v-col>
                <h1>ORMP Update List</h1>
            </v-col>
            <v-col>
                <v-row>
                <v-select
                    :items="monthsSelection"
                    v-model="monthSelected"
                    label="Month Selection"
                    outlined
                    style="width: 150px;"
                ></v-select>
                </v-row>
            </v-col>            
        </v-row>
        <v-row class="mt-2">
            <v-col>
                <v-card
                    class="mx-auto pa-4"
                    tile
                >
                    <h3>For Update ({{forUpdateList.length}})</h3>
                    <div v-if="forUpdateList.length > 0">
                    <v-list-item two-line v-for="item in forUpdateList" :key="item.department">
                    <v-list-item-content>
                        <v-list-item-title>{{item.department}}</v-list-item-title>
                        <v-list-item-subtitle class="text-caption">STATUS: {{item.status}}</v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                    </div>

                    <v-list-item two-line class="text-center" v-else>
                    <v-list-item-content>
                        <v-list-item-title>NO DATA TO DISPLAY</v-list-item-title>
                        <v-list-item-subtitle v-if="monthSelected == ''">Please select a month above.</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>No Results Found.</v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col>
                <v-card
                    class="mx-auto pa-4"
                    tile
                >
                    <h3>Updated ({{UpdatedList.length}})</h3>


                    <div v-if="UpdatedList.length > 0">
                    <v-list-item two-line v-for="item in UpdatedList" :key="item.department">
                    <v-list-item-content>
                        <v-list-item-title>{{item.Department}}</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    </div>

                    <v-list-item two-line class="text-center" v-else>
                    <v-list-item-content>
                        <v-list-item-title>NO DATA TO DISPLAY</v-list-item-title>
                        <v-list-item-subtitle v-if="monthSelected == ''">Please select a month above.</v-list-item-subtitle>
                        <v-list-item-subtitle v-else>No Results Found.</v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data: () => ({
        deptFilter: 'SYSDEV',
        months: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'], 
        monthSelected: ''


    }),
    props: ['query'],
    created(){
      if(this.query){
        this.monthSelected = this.query
      }
    },
    firestore(){
        return {
            ORMPCoverage: this.$db.collection('ORMPCoverage').orderBy('CreatedAt','desc'),
            Departments: this.$db.collection('Departments'),
        }
    },
    computed:{
        DepartmentsFilter(){
            return this.Departments.filter(a=>{
                return a.departmentName !== 'RISK MANAGEMENT TEAM'
            })
        },
        monthsSelection(){
            return this.months.map(a=>{
                return `${a} ${this.$moment().format('YYYY')}`
            })
        },
        forUpdateList(){
            let month = this.monthSelected
            if(month == '') {
                return []
            }

            let filtered = this.ORMPCoverage.filter(a=>{
                return this.$moment(a.startDate).format('MMM YYYY') == month && a.isApproved == false
            })
            console.log(filtered,'forUpdateList');
            let map = this.DepartmentsFilter.map(a=>{
                return {
                    department: a.departmentName,
                    data: this.returnDataFromCoverage(a.departmentName,filtered),
                    status: this.returnDataFromCoverage(a.departmentName,filtered) == null ? 'NONE' : 'FOR APPROVAL'
                }
            })

            return map.filter(a=>{
                return this.$lodash.findIndex(this.UpdatedList,x=>{
                    return x.Department == a.department
                }) == -1
            })
        },
        UpdatedList(){
            let month = this.monthSelected
            if(month == '') {
                return []
            }

            let filtered = this.ORMPCoverage.filter(a=>{
                return this.$moment(a.startDate).format('MMM YYYY') == month && a.isApproved == true
            })
            console.log(filtered,'UpdatedList');
            return filtered
        }
    },
    methods:{
        returnDataFromCoverage(dept,array){
            let filter = array.filter(a=>{
                return a.Department == dept
            })
            return filter[0] ?? null
        }
    }
}
</script>